import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

const FindPollingPlace = ({ data }) => {
  return (
    <Layout title={data.markdownRemark.frontmatter.heading}>
      <Helmet defer={false}>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
        <script src="https://cdn.voteamerica.com/embed/tools.js" async></script>
      </Helmet>
      <Header background={data.markdownRemark.frontmatter.headerImage.photo} alt={data.markdownRemark.frontmatter.headerImage.altText}>{data.markdownRemark.frontmatter.heading}</Header>
    </Layout>
  )
}

export const pageQuery = graphql`query PollingPlaceQuery {
  markdownRemark(frontmatter: {title: {eq: "Polling Place"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
    }
  }
}
`

export default FindPollingPlace